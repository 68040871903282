<!-- 我的等级 -->
<template>
  <div id="VipGrade">
    <div class="grade-warp">
      <!-- 内容部分 -->
      <div class="grade-user">
        <!-- 导航 -->
        <van-nav-bar
          left-arrow
          :title="$t('m_mime.wddj')"
          @click-left="onClickLeft"
        ></van-nav-bar>
        <div class="user-wrap">
          <div class="user-vip-icon">
            <p class="user-icon">
              <img
                v-if="user.avatar == ''"
                src="../../../assets/img/default.jpg"
                alt=""
              />
              <img v-else :src="configUrl + user.avatar" alt="" />
            </p>
            <span class="grade-icon">
              <img
                v-if="user.userlevel"
                :src="configUrl + user.userlevel.img"
                alt=""
              />
            </span>
          </div>
          <p class="grader-text">{{ user.username }}</p>
        </div>
      </div>
      <!-- 等级 -->
      <h2 class="tipc">
        {{$t('m_myRank.xydj')}}
        <img :src="configUrl + next_level.img" alt="" />
      </h2>
      <!-- 环形 -->
      <div class="grade-process">
        <div class="left">
          <div class="circle" style="width: 100px; height: 100px">
            <van-circle
              layer-color="rgb(76, 68, 53)"
              color="rgb(76, 68, 53)"
              :rate="0"
              :stroke-width="60"
              :speed="1000"
            >
              <div class="van-circle__text">
                <p class="text">
                  {{$t('m_myRank.hx')}}
                  <br />
                  <i>{{next_level.touzi_money | privateMoney | privatefg }}&nbsp;{{$t('common.CNY')}}</i>
                </p>
              </div>
            </van-circle>
          </div>
          <div class="label">
            <span class="circle-title">{{$t('m_myRank.tzje')}}</span>
            <p class="circle-text">{{touzi | privateMoney | privatefg }}&nbsp;{{$t('common.CNY')}}</p>
          </div>
        </div>
        <div class="right">
          <div class="circle" style="width: 100px; height: 100px">
            <van-circle
              layer-color="rgb(76, 68, 53)"
              color="rgb(76, 68, 53)"
              :rate="0"
              :stroke-width="60"
              :speed="1000"
            >
              <div class="van-circle__text">
                <p class="text">
                  {{$t('m_myRank.hx')}}
                  <br />
                  <i>{{next_level.chongzhi_money | privateMoney | privatefg }}&nbsp;{{$t('common.CNY')}}</i>
                </p>
              </div>
            </van-circle>
          </div>
          <span class="circle-title">{{$t('m_myRank.czje')}}</span>
          <p class="circle-text">{{chongzhi | privateMoney | privatefg }}&nbsp;{{$t('common.CNY')}}</p>
        </div>
      </div>
      <!-- 当前特权 -->
      <div class="Jurisdiction">
        <img src="../../../assets/img/current_vip.png" alt="" class="cur-vip" v-if="$i18n.locale == 'zh'"/>
        <img src="../../../assets/img/dqtq_en.png" alt="" class="cur-vip" v-else-if="$i18n.locale == 'english'"/>
        <img src="../../../assets/img/dqtq_v.png" alt="" class="cur-vip" v-else/>
        <ul>
          <li>
            <img src="../../../assets/img/grade-pic_2.png" /><span
              >{{$t('m_myRank.zkzj')}}{{user.userlevel.zhuanka}}%</span
            >
          </li>
          <li>
            <img src="../../../assets/img/grade-pic_2.png" /><span
              >{{$t('m_myRank.tzfl')}}</span
            >
          </li>
        </ul>
      </div>
      <!-- 如何升级 -->
      <div class="upgrade">
        <img src="../../../assets/img/top-vip.png" alt="" class="cur-vip"  v-if="$i18n.locale == 'zh'"/>
        <img src="../../../assets/img/rhsj_en.png" alt="" class="cur-vip"  v-else-if="$i18n.locale == 'english'"/>
        <img src="../../../assets/img/rhsj_v.png" alt="" class="cur-vip"  v-else/>
        <div class="cont">
          <img src="../../../assets/img/grade-pic.png" alt="" />
          <p>{{$t('m_myRank.sjjs')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserLevel } from "@/api/user";
export default {
  name: "",
  data() {
    return {
      hxText: "",
      configUrl: process.env.VUE_APP_BASE_API,
      user: {
        username: "--",
        avatar: "",
        userlevel: null,
      },
      next_level: {
        img: "",
      },
      chongzhi: 0,
      touzi: 0
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getUserLevelFun();
  },
  watch: {
    $route: "getUserLevelFun",
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 查询我的等级 */
    getUserLevelFun() {
      getUserLevel().then((res) => {
        console.log(res);
        this.user = res.data.user;
        this.next_level = res.data.next_level;
        this.chongzhi = res.data.chongzhi;
        this.touzi = res.data.touzi;
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
#VipGrade {
  width: 100%;
  min-height: 100%;
  background: #222222;
  padding-bottom: 0.5rem;

  .grade-user {
    width: 100%;
    height: 4.5rem;
    background: url(../../../assets/img/grade-bg.png) no-repeat center;
    background-origin: content-box;
    background-size: 100% 100%;
    position: relative;

    .user-wrap {
      position: absolute;
      left: 50%;
      top: 45%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      margin-top: 0.5rem;

      .user-vip-icon {
        position: relative;
        width: 1.43rem;
        height: 1.43rem;
        margin: 0 auto;

        .user-icon {
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .grade-icon {
          position: absolute;
          bottom: -0.25rem;
          left: 50%;
          width: 1rem;
          height: 0.5rem;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

          img {
            width: 1rem;
            max-height: 0.5rem;
          }
        }
      }
    }

    .user-wrap > p {
      text-align: center;
      color: #ffffff;
      line-height: 0.8rem;
      font-size: 0.3rem;
    }
  }

  .tipc {
    width: 100%;
    color: #ffffff;
    font-size: 0.32rem;
    line-height: 0.5rem;
    text-align: center;
    font-family: PingFang-SC-Medium;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.4rem;

    img {
      width: 1rem;
      margin-left: 0.1rem;
      max-height: 0.5rem;
    }
  }
  /* 环形 */
  .grade-process {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.2rem 0;
  }

  .grade-process > div {
    flex: 1;
    text-align: center;

    .circle {
      margin: 0 auto;
      position: relative;

      .text {
        font-size: 0.22rem;
        color: #ffffff;
        line-height: 0.4rem;

        i {
          font-size: 0.26rem;
          color: #d5b377;
        }
      }
    }

    .circle-title {
      width: 1.3rem;
      height: 0.5rem;
      text-align: center;
      color: #ffffff;
      font-size: 0.26rem;
      line-height: 0.5rem;
    }
    .circle-text {
      color: #ffffff;
      font-size: 0.26rem;
    }
  }

  .Jurisdiction {
    width: 6.86rem;
    height: 2.7rem;
    margin: 0.2rem auto;
    background-image: url(../../../assets/img/line-box.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    position: relative;

    .cur-vip {
      width: 1.64rem;
      position: absolute;
      left: 50%;
      margin-left: -0.82rem;
      top: -0.15rem;
    }

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 0 0.1rem;

      li {
        flex: 1;
        text-align: center;

        img {
          width: 1.2rem;
          margin-top: 0.5rem;
        }

        span {
          display: block;
          width: 100%;
          color: #d5b377;
          text-align: center;
          font-size: 0.22rem;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-top: 0.05rem;
        }
      }
    }
  }

  .upgrade {
    width: 6.86rem;
    height: 4.12rem;
    margin: 0.5rem auto 0;
    background-image: url(../../../assets/img/line-2-box.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    position: relative;
    box-sizing: border-box;

    .cur-vip {
      width: 1.64rem;
      position: absolute;
      left: 50%;
      margin-left: -0.82rem;
      top: -0.15rem;
    }

    .cont {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 6rem;
      text-align: center;
      padding: 0.2rem 0;

      img {
        width: 4.2rem;
        margin-top: 0.3rem;
      }

      p {
        color: #d5b377;
        font-size: 0.26rem;
        line-height: 0.3rem;
      }
    }
  }
}
</style>